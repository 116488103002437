var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "grid",
            attrs: {
              title: "수질오염물질 측정내용",
              tableId: "grid",
              columns: _vm.grid.columns,
              gridHeight: _vm.grid.height,
              data: _vm.grid.data,
              usePaging: false,
              hideBottom: false,
              columnSetting: false,
              filtering: false,
              isExcelDown: false,
              editable: _vm.editable,
              gridHeightAuto: _vm.param.isFullScreen,
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [_c("q-btn-group", { attrs: { outline: "" } })],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }